<template>
  <div class="main-container">
    <div class="home-banner" :style="{'background-image': `url(${homebannerUrl})`, height: '336px'}"></div>
    <!-- <el-carousel height="336px">
      <el-carousel-item class="home-banner" :style="{'background-image': `url(${homebannerUrl})`}">
      </el-carousel-item>
      <el-carousel-item class="home-banner" :style="{'background-image': `url(${homebannerUrl})`}">
      </el-carousel-item>
      <el-carousel-item class="home-banner" :style="{'background-image': `url(${homebannerUrl})`}">
      </el-carousel-item>
    </el-carousel> -->
    <div class="feature">
      <div class="feature-left">
        <h1 class="h1-title">矩尺分布式应用交付平台</h1>
        <div class="title-devide-line"></div>
        <p>矩尺分布式应用交付平台采用控制面和数据面解耦的体现架构，实现了分布式集群部署和统一运维管理；以应用负载均衡功能为核心，集静态资源发布、第三方负载均衡纳管等多项优势功能，为用户带来敏捷、可靠、易维护的应用交付解决方案。</p>
      </div>
      <img src="@/assets/imgs/home-feature.png" alt="矩尺分布式应用交付平台">
    </div>
    <div class="advantage">
      <div class="advantage-img-wrap">
        <h2>服务器负载均衡技术优势</h2>
        <div class="advantage-items-box">
          <div class="advantage-item">
            <h3>可靠性</h3>
            <p>保障应用</p>
            <p>始终可用</p>
          </div>
          <div class="advantage-item">
            <h3>定制化</h3>
            <p>定制化</p>
            <p>开发服务</p>
          </div>
          <div class="advantage-item">
            <h3>可扩展</h3>
            <p>根据需求</p>
            <p>构建集群</p>
          </div>
          <div class="advantage-item">
            <h3>智能监控</h3>
            <p>全面应用</p>
            <p>数据收集</p>
          </div>
          <div class="advantage-item">
            <h3>保护数据</h3>
            <p>端到端的</p>
            <p>SSL加密</p>
          </div>
          <div class="advantage-item">
            <h3>统一管理</h3>
            <p>统一的管理</p>
            <p>操作平台</p>
          </div>
        </div>
      </div>
      <div class="advantage-list-wrap">
        <h3>提供应用级的负载均衡</h3>
        <ul>
          <div class="advantage-list-row">
            <li>负载算法：多种静态、动态的L4-L7负载算法，基于选择合适的计算资源进行分配</li>
            <li>应用级深度健康检查：基于应用协议和应用内容的健康检测</li>
          </div>
          <div class="advantage-list-row">
            <li>Session级会话保持：IP、Cookie、HTTP-Header</li>
            <li>减少宕机时间并规模应用</li>
          </div>
        </ul>
        <div style="height:24px;">
          <!-- <a href="#" class="detail-link">详情点击   ＞</a> -->
        </div>
      </div>
    </div>
    <div class="algorithm">
      <h1 class="h1-title">矩尺网络服务器负载均衡算法</h1>
      <div class="title-devide-line"></div>
      <div class="algorithm-items-wrap">
        <div class="algorithm-item">
          <img src="@/assets/imgs/home-least-connection.png" alt="动态连接数" loading="lazy">
          <div class="algorithm-devide-line"></div>
          <h3>动态连接数</h3>
          <p>下一个连接的分发取决于服务器集群的动态连接数对比结果。</p>
        </div>
        <div class="algorithm-item">
          <img src="@/assets/imgs/home-weighted-round-robin.png" alt="加权轮询" loading="lazy">
          <div class="algorithm-devide-line"></div>
          <h3>加权轮询</h3>
          <p>后台提供服务的服务器性能不一致，可采用加权轮询的算法。</p>
        </div>
        <div class="algorithm-item">
          <img src="@/assets/imgs/home-fastest-response-speed.png" alt="动态响应速度" loading="lazy">
          <div class="algorithm-devide-line"></div>
          <h3>动态响应速度</h3>
          <p>下一个连接的分发取决于服务器集群的动态响应速度对比结果。</p>
        </div>
        <div class="algorithm-item">
          <img src="@/assets/imgs/home-consistent-hash.png" alt="一致性哈希" loading="lazy">
          <div class="algorithm-devide-line"></div>
          <h3>一致性哈希</h3>
          <p>基于源地址的一致性Hash。</p>
        </div>
      </div>
      <p>矩尺网络负载均衡设备旨在协助客户完成容器及微服务改造的云原生进化，提高客户的运营效率、降低资本支出与运营支出，提高业务的可扩展性。</p>
      <div class="algorithm-learn-more-wrap">
        <!-- <button>
          <a>了解更多</a>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      homebannerUrl: require('@/assets/imgs/home-banner.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.home-banner {
  background-size: 100% 100% ;
  background-repeat: no-repeat;
}
.feature {
  width: 100%;
  height: 434px;
  display: flex;
  padding: 0 80px;
  .feature-left {
    width: 35%;
    text-align: left;
    margin-right: 120px;
    margin-top: 70px;
    .h1-title {
      padding: 10px 0;
      font-weight: 400;
      color: #171718;
      line-height: 35px;
    }
    p {
      text-indent: 2rem;
      line-height: 2rem;
    }
  }
  img {
    height: 210px;
    margin-top: 80px;
  }
}
.advantage {
  height: 350px;
  width: 100%;
  background-color: #fff;
  position: relative;
  .advantage-img-wrap {
    position: absolute;
    top: -71px;
    width: 100%;
    height: 220px;
    background-image: url('~@/assets/imgs/home-advantage.png');
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 20px;
      margin-top: 44px;
      color: $primaryColor;
      font-weight: 700;
    }
    .advantage-items-box {
      width: 790px;
      display: flex;
      margin-top: 27px;
      justify-content: space-around;

      .advantage-item {
        width: 100px;
        height: 100px;
        background-image: url('~@/assets/imgs/home-advantage-circle.png');
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          color: $primaryColor;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
        p {
          margin: 0;
          font-size: 14px;
          line-height: 20px;
          color: #7F7F7F;
        }
      }
    }
  }
  .advantage-list-wrap {
    width: 1170px;
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
    text-align: left;
    h3 {
      font-size: 18px;
      font-weight: 700;
      color: #373737;
      line-height: 42px;
    }
    ul {
      list-style: disc;
      padding-left: 16px;
      .advantage-list-row {
        display: flex;
        li:nth-child(odd) {
          width: 60%;
          line-height: 32px;
        }
      }
    }
    .detail-link{
      line-height: 50px;
      color: #409eff;
    }
  }
}
.algorithm {
  height: 555px;
  padding: 18px 104px;
  h1 { 
    line-height: 55px;
  }
  .title-devide-line{
    display: inline-block;
    margin-bottom: 36px;
  }
  .algorithm-items-wrap{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .algorithm-item{
      width: 255px;
      height: 290px;
      border: 1px solid #f9c6a1;
      background-color: #fff;
      padding: 25px 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 88px;
        height: 88px;
      }
      .algorithm-devide-line{
        border-bottom: 1px solid #dbdbdb;
        height: 0;
        width: 181px;
        margin: 24px 0;
      }
      h3 {
        font-size: 18px;
        font-weight: 700;
        color: #233D62;
      }
      p {
        text-align: justify;
        font-size: 15px;
        color: #797777;
      }
    }
  }
  .algorithm-learn-more-wrap{
    button {
      color: #fff;
      border: 1px solid $primaryColor;
      border-radius: 2px;
      background-color: $primaryColor;
      width: 205px;
      height: 45px;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
